@keyframes flickering {
  0% {
    background-color: transparent;
  }
  49% {
    background-color: transparent;
  }
  50% {
    background-color: white;
  }
  100% {
    background-color: white;
  }
}

@keyframes fadeIn {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}