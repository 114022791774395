#root {
    min-height: 100vh;
    background: #000428;
    background: -webkit-linear-gradient(to right, #000428, #004e92);
    background: linear-gradient(to right, #000428, #004e92);
}

#particles canvas {
    width: 100% !important;
    height: 100% !important;
}